import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Layout from '../components/Layout';
import { COLORS } from '../styles/constants';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: 'white',
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid #eee`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    width: '100%',
    textDecoration: 'none',
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 330,
  },
}));

const tiers = [
  {
    title: 'Try it out',
    price: '0',
    per: 'mo',
    link: 'https://app.pushback.io/tryitout',
    description: [
      '100 messages',
      '1 synchronous connection',
      'Share Links',
      'Webhooks',
    ],
    buttonText: 'Try it out',
    buttonVariant: 'outlined',
  },
  {
    title: 'Individual Use',
    subheader: 'Most popular',
    price: '12',
    per: 'yr',
    link: 'https://app.pushback.io/login',
    description: [
      'Unlimited messages',
      '5 synchronous connections',
      'Share Links',
      'Webhooks',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Small Business',
    price: '5',
    per: 'mo',
    link: 'mailto: support@pushback.io',
    description: [
      'Unlimited messages',
      '100 synchronous connections',
      'Share Links',
      'Webhooks',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'Enterprise',
    price: '30',
    per: 'mo',
    link: 'mailto: support@pushback.io',
    description: [
      'Lots of connections/messages',
      'Multiple Users',
      'Share Links',
      'Webhooks',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function Pricing() {
  const classes = useStyles();

  return (
    <Layout>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          component="p"
        ></Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="lg" component="main">
        <Grid container spacing={2} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise' ? 12 : 6}
              md={4}
              lg={3}
            >
              <Card className={classes.card}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent style={{ flex: 1 }}>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /{tier.per}
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map(line => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <a className={classes.link} href={tier.link}>
                    {tier.buttonVariant === 'outlined' ? (
                      <Button
                        style={{
                          border: `1px solid ${COLORS.pushback}`,
                          color: COLORS.pushback,
                          marginBottom: 10,
                        }}
                        fullWidth
                        variant={tier.buttonVariant}
                      >
                        {tier.buttonText}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: COLORS.pushback,
                          color: 'white',
                          marginBottom: 10,
                        }}
                        fullWidth
                        color="primary"
                      >
                        {tier.buttonText}
                      </Button>
                    )}
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Layout>
  );
}
